<template>
  <div class="max-w-7xl mx-auto pb-12 px-4 sm:px-6 lg:px-8">
    <div class="bg-white rounded-lg shadow px-5 py-6 sm:px-6">
      <div class="pb-8 sm:flex sm:items-center sm:justify-between">
        <div>
          <h3 class="text-lg leading-6 font-medium text-gray-900">
            Material de apoio
          </h3>
          <p class="mt-1 max-w-2xl text-sm text-gray-500">
            Confira nossas playlists de tutoriais e materiais de apoio no Youtube.
          </p>
        </div>
        <a href="https://www.youtube.com/user/VWSolucoes" target="_blank" class="w-full sm:w-auto mt-3 sm:mt-0 inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary hover:bg-primarydark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary">
          Acessar canal
        </a>
      </div>
      <div v-for="(item, index) in items" :key="index" class="py-3">
        <p class="text-sm font-medium text-gray-900">{{ item.title }}</p>
        <p class="py-2 xs:mb-2 text-sm text-gray-500 text-justify">{{ item.subtitle }}</p>
        <iframe style="filter: invert(8%)" class="rounded-lg hover:shadow-lg" width="100%" height="315" :src="item.video" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      </div>
    </div>
  </div>
</template>

<script>
import { listingsColl } from '@/firebase'

export default {
  data() { return { items: [] } },

  async mounted() {
    if (!this.$store.state.loading) this.$store.commit('setLoading', true)
    await listingsColl.doc('LearnIt').get()
    .then((result) => { this.items = result.data().available })
    .catch(() => { this.$toast.error('Falha ao obter o conteúdo, tente novamente') })
    if (this.$store.state.loading) this.$store.commit('setLoading', false)
  }
}
</script>